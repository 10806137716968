<template>
    <v-data-table
        :headers="headers"
        :items="languages.data"
        :items-per-page="paginationParams.per_page"
        :page="paginationParams.page"
        :server-items-length="languages.total"
        :loading="loading"
        sort-by="id"
        class="border"
        @update:page="changePage"
        @update:items-per-page="changeItemsPerPage"
        :footer-props="footerProps"
        @update:sort-by="sortBy"
        @update:sort-desc="sortDesc"

    >
        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-dialog v-model="dialog" max-width="800px">
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark class="mb-2" v-on="on">{{ trans('buttons.language.newItem') }}</v-btn>
                    </template>
                    <language-form v-if="dialogLoaded" :options="options" :edited-item="editedItem" v-on:saved="onSave" v-on:close="close"></language-form>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:item.created_at="{ item }">
            {{ item.created_at | mysqlDate }}
        </template>
        <template v-slot:item.translations="{ item }">
            <v-progress-circular
                v-if="translationProgress"
                :value="translationProgress[item.id]"
                :color="translationProgress[item.id] < 100 ? 'red' : 'green'"
            >
                {{ translationProgress[item.id] }}
            </v-progress-circular>
        </template>


    </v-data-table>
</template>

<script>
    import tableWithPagination from "@/mixins/tableWithPagination";

    export default {
        name: "LanguageTable",
        components: {
            LanguageForm: () => import('@/components/admin/language/LanguageForm'),
        },
        mixins: [tableWithPagination],
        data() {
            return {
                dialog: false,
                dialogLoaded: false,
                languages: {},
                headers: [
                    { text: "id", sortable: false, value: "id" },
                    { text: this.trans("fields.common.label"), value: "label", sortable: false },
                    { text: this.trans("fields.common.locale"), value: "locale", sortable: false },
                    { text: this.trans("fields.common.created_at_u"), value: "created_at", sortable: false },
                    { text: this.trans("fields.common.translations"), value: "translations", sortable: false },
                    { text: this.trans("fields.common.actions"), value: "actions", sortable: false },
                ],
                paginationParams: {
                    page: 1,
                    per_page: 10,
                },
                url: this.route('admin.languages.index'),

                editedItem: {},
                defaultItem: {
                    id: "",
                    label: "",
                    locale: "",
                    translations: {},
                },
            }
        },
        created() {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.initialize();
            this.loadOptions();
        },
        watch: {
            dialog(val) {
                if (val){
                    this.dialogLoaded = true
                } else {
                    this.close();
                }
            }
        },
        methods: {
            onSave(){
                this.close();
                this.initialize();
            },
            editItem(item) {
                this.editedItem = JSON.parse(JSON.stringify(item));
                this.dialog = true;
                this.dialogLoaded = true;
            },
            deleteItem(item) {
                confirm("Are you sure you want to delete this item?") &&
                console.log(['deleted', item]);
            },

            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.dialogLoaded = false;
                }, 300);
            },


        },
        computed: {
            translationProgress(){
                if (!this.paginationItems.data){
                    return null;
                }
                let progress = {};
                _.each(this.paginationItems.data, (item) => {
                    progress[item.id] = Math.floor(_.reduce(item.translations, (num, tr) => { return num + (tr !== "" ? 1 : 0);}, 0 ) / Object.values(item.translations).length * 100);
                })
                return progress;
            },
            paginationItems: {
                get(){
                    return this.languages;
                },
                set(v){
                    this.languages = v;
                }
            },
        }
    }
</script>

<style scoped>

</style>
